import './Home.css';
import { Typewriter } from 'react-simple-typewriter';

const Home = () => {
  const handleDone = () => {};

  return (
    <section className='home container'>
      <div className='home-wrapper'>
        <h2 className='home-header'>
          <span className='home-header-span'>Asistentes</span> con
        </h2>
        <h2 className='home-header'>Inteligencia Artificial</h2>
        {/* <h2 className='home-header'>
          <span className="whatsapp-color">WhatsApp</span><br />
          <span className="facebook-color">Facebook</span><br />
          <span className="instagram-color">Instagram</span>
        </h2> */}
        <h2 className='type-header'>
          para&nbsp;
          <Typewriter
            words={[
              'Atención a cliente',
              'Soporte',
              'Ventas',
            ]}
            loop={1000}
            cursor
            cursorStyle='_'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
            onLoopDone={handleDone}
          />
        </h2>
        <p className='home-paragraph'>
          Mensajes de &nbsp;
          <span className='whatsapp-color'>WhatsApp</span>,&nbsp;
          <span className='instagram-color'>Instagram</span> y&nbsp;
          <span className='facebook-color'>Facebook</span> con inteligencia artificial.
        </p>
        <div>
          <a
            href="/"
            className='work-with-us-button'
            onClick={(e) => {
              e.preventDefault();
              if (window.location.pathname === '/') {
                let element = document.getElementById('calculadora');
                element &&
                  element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
              } else {
                window.location.href = '/#about-us';
              }
            }}>
            Ver Planes
          </a>
          <a
            href='/'
            className='learn-more-button'
            onClick={(e) => {
              let element = document.getElementById('traits');
              e.preventDefault();
              element &&
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
          >
            Learn more
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
